<template>
  <div class="list">
    <nav-bar :title="title"></nav-bar>
    <ul class="main">
      <li v-for="(item, index) in list" :key="index" @click="leftClick(item)">
        <div
          :style="item.pictureUrl ? 'width:4.5rem' : 'width:100%'"
          class="left"
        >
          <h3>{{ item.title }}</h3>
          <p v-show="item.briefIntroduction">{{ item.briefIntroduction }}</p>
        </div>
        <img
          v-show="item.pictureUrl"
          :src="item.pictureUrl"
          alt=""
          class="right"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import NavBar from "@/components/content/navBar/NavBar.vue";
import { getFlfgData } from "@/network/flfg.js";
import { getDxalData } from "@/network/dxal.js";
export default {
  data() {
    return {
      title: null,
      type: null,
      list: [],
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.type = this.$route.query.type;
    if (this.type === "1") {
      this.initFlfg();
    } else {
      this.initDaxl();
    }
  },
  methods: {
    async initFlfg() {
      const result = await getFlfgData();
      this.list = result.data;
    },
    async initDaxl() {
      const result = await getDxalData();
      this.list = result.data;
    },
    leftClick(item) {
      this.$router.push({
        name: "Content",
        query: { item: JSON.stringify(item),type:this.type },
      });
    },
  },
  components: {
    NavBar,
  },
};
</script>
<style scoped>
ul {
  padding: 0.3rem;
  padding-top: 1.3rem;
  box-sizing: border-box;
}
li {
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.2rem;
  margin-bottom: 0.26rem;
}
img {
  width: 2rem;
  height: 1.4rem;
  border-radius: 0.05rem;
  margin-left: 0.4rem;
}
.left {
  /* flex: 1; */
}
li h3 {
  line-height: 1.3;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
li p {
  line-height: 1.5;
  font-size: 0.24rem;
  color: #999999;
  margin-top: 0.33rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>