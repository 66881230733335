<template>
  <header>
    <img
      @click="leftClick"
      class="left"
      src="~assets/img/header/左箭头.png"
      alt=""
    />
    <h2>{{ title }}</h2>
    <img
      @click="rightClick"
      class="right"
      src="~assets/img/header/主页.png"
      alt=""
    />
  </header>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    leftClick() {
      window.history.back();
    },
    rightClick() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
<style scoped>
header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 0.88rem;
  background: #9c4c39;
  text-align: center;
}
header h2 {
  font-size: 0.34rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.88rem;
}
.left,
.right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.left {
  width: 0.33rem;
  height: 0.3rem;
  left: 0.3rem;
  padding-right: .3rem;
}
.right {
  width: 0.4rem;
  height: 0.37rem;
  right: 0.3rem;
}
</style>