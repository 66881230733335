import { request } from './request.js'

export function getFlfgData() {
    return request({
        url: '/LawsController/queryLawsInfoList.json',
    })
}


export function flfgDetails(id) {
    return request({
        url: `/LawsController/getLawsInfoById.json?id=${id}`
    })
}