import { request } from './request.js'

export function getDxalData() {
    return request({
        url:'/ClassicCases/queryClassicCasesList.json',
    })
}


export function dxalDetails(id){
    return request({
        url:`/ClassicCases/getClassicCasesInfoById.json?id=${id}`,
    })
}